import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';


import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import 'bootstrap/dist/css/bootstrap.min.css';

import { FormGroup, Label, Input} from 'reactstrap';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class EditSenha extends Component {


    constructor(props) {
        super(props);
   
        this.state = {
            listusers : [],
            nome:"",
            email:"",
            senha:"",
            displayColorPicker: false,
            open: true,
            redirect: false
            };
      }


       componentDidMount(){        
        this.loadUser();
      };

      loadUser = async () => {
        const { id } = this.props.match.params;     
        const response = await api.get(`/api/users/${id}`);
        this.setState({
          listusers: response.data,   
        });
      };


      handleChange_nome = (event) => {
        this.setState({nome: event.target.value});
      };      

      handleChange_email = (event) => {
        this.setState({ email: event.target.value});
      };

      handleChange_senha = (event) => {
        this.setState({ senha: event.target.value});
      };

         
  
      handleUpdate = event => {
        event.preventDefault();
        const { id } = this.props.match.params; 
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://www.agendaseb.com.br/agendaseb/api/users/${id}`, { 
        nome: this.state.nome,
        email: this.state.email,
        senha: this.state.senha,

         }, config)
          .then(res => {
            alert('Usuário alterado com sucesso!');
            this.props.history.push("/admin/usuarios");
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }
  
  

    render() {
        const { listusers,  open } = this.state; 
        const popover = {
          position: 'absolute',
          zIndex: '2',
        }
        const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        } 
        return (
            
            
          <Dialog fullScreen open={open} TransitionComponent={Transition}>

              
   
 
          <AppBar className="appBar" style={{backgroundColor:listusers.cor,}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; {listusers.nome}
                  </Typography>
                 
              </td>
                  <td align="right">
                    <Link to={`/admin/config`}>
                  <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  </Link>
              </td>
                </tr>
              </table>

            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  

            {listusers ? (
          <form className="register-form" onSubmit={this.handleUpdate}>




<GridContainer>  
            
            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="nome">Nome</label>    
            <input className="input_text"
              name="nome"
              id="nome"
              type="text"
              disabled
              placeholder={listusers.nome}
              onChange={this.handleChange_nome}
              />          
              
                        
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="email">E-mail</label>    
            <input className="input_text"
              name="email"
              id="email"
              type="text"
              disabled
              placeholder={listusers.email}
              onChange={this.handleChange_email}
              />          
              
                        
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="senha">Nova senha</label>    
            <input className="input_text"
              name="senha"
              id="senha"
              type="password"
              required="required"
              onChange={this.handleChange_senha}
              />          
              
                        
            </GridItem>

    
          </GridContainer>
          
          <div style={{position:"absolute", right:20, marginTop:10}}>
            <table>
              <tr>             
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                  <button onClick={this.handleUpdate} type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:`${listusers.cor}`}}> Salvar </button>                    
                  </div>
                </td>
            </tr>
              
            </table>
</div>

          </form>
        ) : (
          <div>
           Senão
          </div>
        )}
           
              

  


        
          
            </article>
            </div>
         

           


        </Dialog>
          
       
        );
    }
}