import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';


import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import 'bootstrap/dist/css/bootstrap.min.css';

import { FormGroup, Label, Input} from 'reactstrap';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class EditUsuario extends Component {


    constructor(props) {
        super(props);
   
        this.state = {
            permissoes:[],
            listusers : [],
            listUnidades:[],           
            displayColorPicker: false,
            open: true,
            redirect: false
            };
      }


       componentDidMount(){        
        this.loadUser();
        this.loadUnidades();
      };

      loadUser = async () => {
        const { id } = this.props.match.params;     
        const response = await api.get(`/api/users/${id}`);
        this.setState({
          listusers: response.data,   
        });
      };

      loadUnidades = async () => {    
        const response2 = await api.get("/api/unidadesnegocios_list");
        this.setState({
          listUnidades: response2.data,   
        });
      };




      handleChange_nome = (event) => {
        event.persist();
        this.setState({nome: event.target.value});
      };      

      handleChange_email = (event) => {
        event.persist();
        this.setState({ email: event.target.value});
      };

      handleChange_senha = (event) => {
        event.persist();
        this.setState({ senha: event.target.value});
      };

        handleChange_unidadenegocio_id2 = (event) => {
          event.persist();
        this.setState({ unidadenegocio_id2: event.target.value});
      };

      handleChange_unidadenegocio_id3 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id3: event.target.value});
      };

      handleChange_unidadenegocio_id4 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id4: event.target.value});
      };

      handleChange_unidadenegocio_id5 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id5: event.target.value});
      };

      handleChange_unidadenegocio_id6 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id6: event.target.value});
      };

      handleChange_unidadenegocio_id7 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id7: event.target.value});
      };

      handleChange_unidadenegocio_id8 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id8: event.target.value});
      };

      handleChange_unidadenegocio_id9 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id9: event.target.value});
      };

      handleChange_unidadenegocio_id10 = (event) => {
        event.persist();
        this.setState({ unidadenegocio_id10: event.target.value});
      };

         
  
      handleUpdate = event => {
        event.preventDefault();
        const { id } = this.props.match.params; 
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://www.agendaseb.com.br/agendaseb/api/users/${id}`, { 
        nome: this.state.nome,
        email: this.state.email,
        senha: this.state.senha,
        unidadenegocio_id2: this.state.unidadenegocio_id2,
        unidadenegocio_id3: this.state.unidadenegocio_id3,
        unidadenegocio_id4: this.state.unidadenegocio_id4,
        unidadenegocio_id5: this.state.unidadenegocio_id5,
        unidadenegocio_id6: this.state.unidadenegocio_id6,
        unidadenegocio_id7: this.state.unidadenegocio_id7,
        unidadenegocio_id8: this.state.unidadenegocio_id8,
        unidadenegocio_id9: this.state.unidadenegocio_id9,
        unidadenegocio_id10: this.state.unidadenegocio_id10,
       

         }, config)
          .then(res => {
            alert('Usuário alterado com sucesso!');
            this.props.history.push("/admin/usuarios");
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }
  
      deleteDados = async () => {   
        const { id } = this.props.match.params; 
        const url = 'https://www.agendaseb.com.br/agendaseb/api/users';
        await fetch(url + "/" + id, {
          method: 'DELETE'
        }).then(() => {
          alert('Usuário excluído com sucesso!');
          this.props.history.push("/admin/usuarios");
        }).catch(err => {
          console.error(err)
        });
        };
  

    render() {
        const { listusers,  open, listUnidades } = this.state; 
        const popover = {
          position: 'absolute',
          zIndex: '2',
        }
        const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        } 
        return (
            
            
          <Dialog fullScreen open={open} TransitionComponent={Transition}>

              
   
 
          <AppBar className="appBar" style={{backgroundColor:listusers.cor,}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; {listusers.nome}
                  </Typography>
                 
              </td>
                  <td align="right">
                    <Link to={`/admin/usuarios`}>
                  <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  </Link>
              </td>
                </tr>
              </table>

            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  

            {listusers ? (
          <form className="register-form" onSubmit={this.handleUpdate}>




<GridContainer>  
            
            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="nome">Nome</label>    
            <input className="input_text"
              name="nome"
              id="nome"
              type="text"
              required="required"
              defaultValue={listusers.nome}
              onChange={this.handleChange_nome}
              />          
              
                        
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="email">E-mail</label>    
            <input className="input_text"
              name="email"
              id="email"
              type="text"
              required="required"
              defaultValue={listusers.email}
              onChange={this.handleChange_email}
              />          
              
                        
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="senha">Senha</label>    
            <input className="input_text"
              name="senha"
              id="senha"
              type="text"
              required="required"
              defaultValue={listusers.senha}
              onChange={this.handleChange_senha}
              />          
              
                        
            </GridItem>

    
          </GridContainer>


          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id">Unidade Principal</label> <br />
            <strong>{listusers.unidadenegocio_nome}</strong> 

              
            </GridItem>
          </GridContainer>


            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id2">Unidade 2</label>  

            <select className="input_text"
            name="unidadenegocio_id2"
            id="unidadenegocio_id2"
            onChange={this.handleChange_unidadenegocio_id2}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id2 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
          </GridContainer>

            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id3">Unidade 3</label>  

            <select className="input_text"
            name="unidadenegocio_id3"
            id="unidadenegocio_id3"
            onChange={this.handleChange_unidadenegocio_id3}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id3 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>

            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id4">Unidade 4</label>  

            <select className="input_text"
            name="unidadenegocio_id4"
            id="unidadenegocio_id4"
            onChange={this.handleChange_unidadenegocio_id4}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id4 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>


            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id5">Unidade 5</label>  

            <select className="input_text"
            name="unidadenegocio_id5"
            id="unidadenegocio_id5"
            onChange={this.handleChange_unidadenegocio_id5}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id5 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>

            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id6">Unidade 6</label>  

            <select className="input_text"
            name="unidadenegocio_id6"
            id="unidadenegocio_id6"
            onChange={this.handleChange_unidadenegocio_id6}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id6 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>



            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id7">Unidade 7</label>  

            <select className="input_text"
            name="unidadenegocio_id7"
            id="unidadenegocio_id7"
            onChange={this.handleChange_unidadenegocio_id7}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id7 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>



            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id8">Unidade 8</label>  

            <select className="input_text"
            name="unidadenegocio_id8"
            id="unidadenegocio_id8"
            onChange={this.handleChange_unidadenegocio_id8}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id8 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>


            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id9">Unidade 9</label>  

            <select className="input_text"
            name="unidadenegocio_id9"
            id="unidadenegocio_id9"
            onChange={this.handleChange_unidadenegocio_id9}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id9 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>



            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>  
            <label htmlFor="unidadenegocio_id10">Unidade 10</label>  

            <select className="input_text"
            name="unidadenegocio_id410"
            id="unidadenegocio_id10"
            onChange={this.handleChange_unidadenegocio_id10}
            >
            
            <option value="">Nenhuma unidade selecionada...</option>
            {listUnidades.map(unidades => ( 
            unidades._id === listusers.unidadenegocio_id10 ? (
              <option selected key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option>
            )
            :
            (
              <option key={unidades._id} value={unidades._id}>{unidades.unidadenegocio}</option> 
            )          
            ))} 

            </select>
              
            </GridItem>
            </GridContainer>

          <div style={{position:"absolute", right:20, marginTop:10}}>
            <table>
              <tr>
              <td style={{verticalAlign:"middle", width:150}}>
                  <div style={{paddingTop:20}}>
                  <a onClick={this.deleteDados} style={{border:0, marginTop:5, backgroundColor:"transparent", cursor:"pointer",textDecoration:"none", color:"#f44336"}}>Excluir usuário</a>              
                  </div>
             </td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                  <button onClick={this.handleUpdate} type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:`${listusers.cor}`}}> Salvar </button>                    
                  </div>
                </td>
            </tr>
              
            </table>
</div> 





          </form>
        ) : (
          <div>
           Senão
          </div>
        )}
           
              




        
          
            </article>
            </div>


    



           



        </Dialog>
          
       
        );
    }
}