import React, { Component } from "react";
import api from "../../services/api";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import DataTable from 'react-data-table-component';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import 'webdatarocks/webdatarocks.css';

import * as WebDataRocksReact from 'react-webdatarocks';


import Moment from 'moment';
import 'moment/locale/pt';
import {CSVLink, CSVDownload} from 'react-csv';

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  customFilter
} from "react-bootstrap-table2-filter";



import { Modal, Button, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


import GridItem from "components/Grid/GridItem.js";



export default class Relatorios extends Component {
  

  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.getValue = this.getValue.bind(this);
  }
  getValue() {
    return {
      min: this.min.value,
      max: this.max.value
    };
  }
  filter() {
    this.props.onFilter(this.getValue());
  }

  
 
state = {
  eventos: [],
  colunas:[],
  eventosInfo: {},
  dateStart: "",
  dateEnd: "",
  seletor:"",
  pivot:"",
  
}

componentDidMount(){
this.loadEventos();
};






loadEventos = async () => {
const response = await api.get(`/api/eventos`);  
const { docs, ...eventosInfo } = response.data;
this.setState({
  eventos: docs,
  eventosInfo
});
};





render() {

  

  function handlePdf(){
    const input = document.getElementById('page');

    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF(('p', 'mm', 'a4'));
            var imgWidth = 210;
            var pageHeight = 295;  
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var position = 10; // give some top padding to first page
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
              position = heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
          }
            pdf.save("eventosSEB.pdf");
        });
};

  const columns = [
    {
      name: 'Unidade',
      selector: row => row.unidadenegocio_nome,
      sortable: true,   
      cell: row => (
        <div>
          <div style={{ marginTop:10,}}>
          {row.unidadenegocio_nome}
          </div>
            <div style={{padding:5, marginTop:10, marginBottom:10, width:50, backgroundColor:row.cor, borderRadius:20}}></div>
   
        </div>
      ),  
    },
    {
      name: 'Título',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Data',
      selector: row => Moment(row.start).format('L'),
      sortable: true,
    },
  
    {
      name: 'Horário',
      selector: row => row.horario,
      sortable: false,
    },
    {
      name: 'Público',
      selector: row => row.publico,
      sortable: false,
    },
    {
      name: 'Local',
      selector: row => row.local,
      sortable: false,
    },

 
  ];

  const headers = [
    { label: "", key: "cor" },
    { label: "Unidade", key: "unidadenegocio_nome" },
    { label: "Título", key: "title" },
    { label: "Data", key: "start" }
  ];
  
  const { eventos, eventosInfo,} = this.state; 

  const paginationOptions = {
    rowsPerPageText: 'Eventos por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  


  

  return (
    

    
    <div> 



<GridItem xs={12} sm={12} md={12}>
                     <div align="right" style={{marginBottom:20, marginTop:0,}}>
                     <button style={{
                     backgroundColor:'#333',   
                   
                     borderRadius: 5,
                     color: '#fff',
                     fontSize: 14,
                     textDecoration: 'none',
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding: 10,
                     paddingLeft: 30,
                     paddingRight: 35,
                     fontWeight:400
                     }} onClick={handlePdf}>Exportar PDF</button>
                   </div>
                 </GridItem>


                 <div id="page"> 
                 <Card>
          
          <CardBody style={{padding:20}}>

 

<DataTable

            columns={columns}
            data={eventos}
            paginationComponentOptions={paginationOptions}
            responsive={true}
            noDataComponent="Nenhum evento cadastrado!"
            />
            </CardBody>
            </Card>

     </div>

     
    </div>
  );
}}
