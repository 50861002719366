import React from "react";
import ReactDOM from "react-dom";
import {history} from './history'
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import SignIn from "layouts/SignIn/index";
import AddUnidadesNegocios from "./views/AddUnidadesNegocios/AddUnidadesNegocios";
import AddEventos from "views/AddEventos/AddEventos";
import UnidadeNegocio from "./views/UnidadeNegocio/UnidadeNegocio";
import UnidadeNegocio2023 from "./views/UnidadeNegocio/UnidadeNegocio2023";
import UnidadeNegocio2022 from "./views/UnidadeNegocio/UnidadeNegocio2022";
import EditUnidadeNegocio from "./views/EditUnidadeNegocio/EditUnidadeNegocio";
import EditEvento from "./views/EditEvento/EditEvento";
import EditSenha from "views/EditSenha/EditSenha";
import EditUsuario from "views/EditUsuario/EditUsuario";
import UsersUnidades from "views/UsersUnidades/UsersUnidades";
import AddUser from "views/AddUser/AddUser";
import UserVip from "views/UsersUnidadesVip/UsersUnidadesVip";
import AddUserVIP from "views/AddUserVIP/AddUserVIP";
import Users_New from "views/Users_New/Users_New";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import { isAuthenticated } from "./services/auth";


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    
    render={props =>
      
      isAuthenticated()     
      
      ? (
        <Component {...props} />
      ) : 
      
      (
        <Redirect to={{ pathname: "/admin", state: { from: props.location } }} />
      )
    }
  />
);



ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <PrivateRoute path="/admin" component={Admin} />    
      <PrivateRoute path="/addunidadesnegocios" component={AddUnidadesNegocios} />
      <PrivateRoute path="/addeventos/:id" component={AddEventos} />
      <PrivateRoute path="/addusers/:id" component={AddUser} />
      <PrivateRoute path="/unidadenegocio/:id" component={UnidadeNegocio} /> 
      <PrivateRoute path="/unidadenegocio2022/:id" component={UnidadeNegocio2022} />   
      <PrivateRoute path="/unidadenegocio2023/:id" component={UnidadeNegocio2023} />  
      <PrivateRoute path="/usuarios_negocios/:id" component={UsersUnidades} />  
      <PrivateRoute path="/editunidadenegocio/:id" component={EditUnidadeNegocio} /> 
      <PrivateRoute path="/editevento/:id" component={EditEvento} /> 
      <PrivateRoute path="/edituser/:id" component={EditUsuario} /> 
      <PrivateRoute path="/editsenha/:id" component={EditSenha} /> 
      <PrivateRoute path="/uservip" component={UserVip} /> 
      <PrivateRoute path="/adduservip" component={AddUserVIP} /> 
      <PrivateRoute path="/user_new" component={Users_New} /> 

      
      
      <Route path="*" component={() => <h1>Página não encontrada!</h1>} />   
    </Switch>
  </Router>,
  document.getElementById("root")
);
