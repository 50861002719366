import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';


import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { FormGroup, Label, Input} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';

  import 'react-toastify/dist/ReactToastify.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AddUser extends Component {


    state = {
      unidadenegocio_id: [],
      tipo:"",
      unidadenegocio_id2:"",
      unidadenegocio_nome:"",
      cor:"",
      nome:"",
      email:"",
      senha:"",
      open: true,
      redirect: false
      };

      async componentDidMount(){     
        const { id } = this.props.match.params;
        const response = await api.get(`/api/unidadesnegocios/${id}`);
        this.setState ({
          unidadenegocio_id: response.data,
        });         
      };

     
      handleChange_nome = (event) => {
        this.setState({nome: event.target.value});
      };

      handleChange_email = (event) => {
        this.setState({email: event.target.value});
      };

      handleChange_senha = (event) => {
        this.setState({senha: event.target.value});
      };

      
      
      handleSubmit = event => {
        event.preventDefault();
        const { unidadenegocio_id } = this.state;
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.post('https://www.agendaseb.com.br/agendaseb/api/users', {  
        
        tipo:"3",     
        unidadenegocio_id: unidadenegocio_id._id,
        unidadenegocio_nome: unidadenegocio_id.unidadenegocio,
        cor: unidadenegocio_id.cor,
        nome: this.state.nome,
        email: this.state.email,
        senha: this.state.senha,
        unidadenegocio_id2:"0",
        unidadenegocio_id3:"0",
        unidadenegocio_id4:"0",
        unidadenegocio_id5:"0",
        unidadenegocio_id6:"0",
        unidadenegocio_id7:"0",
        unidadenegocio_id8:"0",
        unidadenegocio_id9:"0",
        unidadenegocio_id10:"0"
         }, config)
          .then(res => {
            alert('Usuário criado com sucesso!');
            this.props.history.push(`/usuarios_negocios/${unidadenegocio_id._id}`);
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }


    render() {

      const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }

        const { open, unidadenegocio_id } = this.state;  
        
        return (
      
          <Dialog fullScreen open={open} TransitionComponent={Transition}>
          <AppBar className="appBar" style={{backgroundColor:`${unidadenegocio_id.cor}`,}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; Criar usuário / {unidadenegocio_id.unidadenegocio}&nbsp;&nbsp;&nbsp;
                  </Typography>
                 
              </td>
                  <td align="right">
                  <Link to={`/usuarios_negocios/${unidadenegocio_id._id}`}>
              <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                <CloseIcon />
              </IconButton>
              </Link>
              </td>
                </tr>
              </table>



             
                 
            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  
           
              <form className="register-form" onSubmit={this.handleSubmit}>


              <GridContainer>  
            
                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="nome">Nome completo</label>    
                  <input className="input_text"
                  placeholder="Digite o nome completo do usuário..."
                    name="nome"
                    id="nome"
                    type="text"
                    required="required"
                    onChange={this.handleChange_nome}
                    />                     
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="email">E-mail</label>    
                  <input className="input_text"
                  placeholder="Digite o e-mail de acesso..."
                    name="email"
                    id="email"
                    type="text"
                    required="required"
                    onChange={this.handleChange_email}
                    />                     
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="senha">Senha</label>    
                  <input className="input_text"
                  placeholder="Digite uma senha de acesso..."
                    name="senha"
                    id="senha"
                    type="text"
                    required="required"
                    onChange={this.handleChange_senha}
                    />                     
                  </GridItem>

      
   
                                
                </GridContainer>
             

            <div style={{position:"absolute", right:20, marginTop:20}}>
            <table>
              <tr>        
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                    <button type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:`${unidadenegocio_id.cor}`}}> Salvar </button>              
                  </div>
                </td>
            </tr>
              
            </table>
</div>
            
            
  


              </form>
            

        
          
            </article>
            </div>
         
        </Dialog>
          
       
        );
    }
}