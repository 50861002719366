import React, { useState, useEffect } from "react";
import JsPDF from 'jspdf';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import {
  Modal,
  ModalBody, 
  Table,
  Alert
} from "reactstrap";


function App() {
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false); 



  const toggle = () => setModal(!modal); 

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a1');
    report.html(document.querySelector('#report')).then(() => {
        report.save('reportAgendaSEB.pdf');
    })};

  

  useEffect(() => {
 
    // axios.get('https://sebstore.app.br/api/troca')
    //     .then(res=>{

    //         const createdAt1 = res.data.createdAt;
    //         const produto1 = res.data.produto.nome;
    //         const unidade1 = res.data.unidade;
    //         const user1 = res.data.user.nome;
    //         const boots1 = res.data.boots;
    //         const status1 = res.data.status;

    //         setData({
    //           createdAt:createdAt1, 
    //           produto: produto1,
    //           unidade: unidade1,
    //           user: user1,
    //           boots: boots1,
    //           status: status1,
    //         })
    //         console.log(data);
    //     })
    //     .catch(err=>{
    //         console.log(err);
    //     })

    

      const trocasData = async () =>{
      const api = await fetch('https://www.agendaseb.com.br/agendaseb/api/eventosR')
      const docs = await api.json();
      setData(docs);
       console.log(docs);
      
      
    };
    trocasData();
  }, []);



  const handleDateChange = (event, key) => {
    const newDate = new Date(event.target.value);
    setDateRange((prev) => ({ ...prev, [key]: newDate }));
  };

 

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.createdAt);
    return (!dateRange.start || itemDate >= dateRange.start ) && (!dateRange.end || itemDate <= dateRange.end);
  })


  




  const columns = [{
    id: 'createdAt',
    displayName: 'Data'
  }, 
  {
    id: 'unidadenegocio_nome',
    displayName: 'Unidade'
  },
  {
    id: 'title',
    displayName: 'Título'
  },
  {
    id: 'start',
    displayName: 'Data do Evento'
  },
  {
    id: 'horario',
    displayName: 'Hora do Evento'
  },
  {
    id: 'local',
    displayName: 'Local'
  },
  {
    id: 'desc',
    displayName: 'Descrição'
  },
];



  return (
    
    <div className='main'>

     
            <Modal isOpen={modal} 
                toggle={toggle}> 
                <ModalBody> 
                    Simple Modal with just ModalBody... 
                </ModalBody> 
            </Modal> 
  



        <div id="report" name="report" className="section section-buttons" style={{margin:40}}>
 
  
      <label className='start-date-label' style={{fontWeight:"600"}}>Data Inicial </label>&nbsp;&nbsp;
      <input type="date" className='start-date-input' onChange={(e) => handleDateChange(e, 'start')} />
      &nbsp;&nbsp;&nbsp;
      <label className='end-date-label' style={{fontWeight:"600"}}>Data Final </label>&nbsp;&nbsp;
      <input type="date" className='end-date-input' onChange={(e) => handleDateChange(e, 'end')} />
      &nbsp;&nbsp;&nbsp;

      <CsvDownloader
       style={{fontWeight:"600", padding:10}}
        filename="PlanilhaSebStore"
        extension=".csv"
        separator=";"
        columns={columns}
        datas={filteredData}
        text="Exportar Planilha" />
&nbsp;&nbsp;&nbsp;
<button  style={{fontWeight:"600", padding:10}} onClick={generatePDF} type="button">Exportar PDF</button>




<br /><br />
      <Table striped>
      <thead>
        <tr>
         
          <th>Data</th>
          <th>Unidade</th>  
          <th>Título</th>  
          <th>Data</th> 
          <th>Horário</th> 
          <th>Local</th>
          <th>Descrição</th>   
        </tr>
      </thead>
      <tbody>
        {filteredData.length === 0 ? (
          <p className='no-data-message'>Nenhum evento encontrado.</p>
        ) : (
          filteredData.map((item3) => (
            <tr key={item3._id}>    
            <td> {moment(item3.createdAt).format('DD/MM/YYYY')}</td> 


            <td style={{fontWeight:400}}> 
            <div style={{backgroundColor:`${item3.cor}`, color:"#FFF", padding:5}}>{item3.unidadenegocio_nome}</div></td>  
            <td style={{fontWeight:400}}> 
       
           {item3.title}
   
            
            </td> 
            <td> {moment(item3.start).format('DD/MM/YYYY')}</td>    
            <td> {item3.horario}</td>   
           
            <td style={{fontWeight:400}}> {item3.local}</td>  
            <td style={{fontWeight:400}}> {item3.desc}</td>  
           


           

            </tr>
          ))
        )}
        </tbody>
      </Table>
   
      </div>
    </div>
  );
  }
  export default App;