import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Logo from "../../assets/img/gruposeb.png";
import api from "../../services/api";
import { login } from "../../services/auth";
import { Form, Container } from "./styles";
import '../../Sytles/styles.css';

class SignIn extends Component {
  state = {
    email: "",
    senha: "",
    error: ""
  };

  handleSignIn = async e => {
    e.preventDefault();
    const { email, senha } = this.state;
    if (!email || !senha) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("auth/authenticate", { email, senha });
        const token = response.data.token; 
        const userlog = response.data.user;
        const user = JSON.stringify(userlog);

        login(user, token);        
        this.props.history.push("/admin");
        
      } catch (err) {
        
        this.setState({
          error:
            "Houve um problema com o login, por favor verifique suas credenciais ou entre em contato com o Eventos do Grupo SEB."
        });
      }
    }
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignIn}>
          <img src={Logo} style={{width:130, marginTop:10}} alt="Grupo SEB Eventos" />
          <label>Eventos Grupo SEB</label>
         
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => this.setState({ email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={e => this.setState({ senha: e.target.value })}
          />
           {this.state.error && <p>{this.state.error}</p> }
          <button type="submit" style={{cursor:'pointer',}}>Entrar</button>   
         
         <div style={{fontSize:14, marginTop:20, fontWeight:400,}}>Copyright © Grupo SEB.</div> 
        </Form>
        

      </Container>
    );
  }
}

export default withRouter(SignIn);