import React, { useState, useEffect } from "react";
import JsPDF from 'jspdf';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import {
  Modal,
  ModalBody, 
  Table,
  Alert
} from "reactstrap";


function App() {
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false); 
  const [selectedValue, setSelectedValue] = useState('Selecione a Unidade');
  const [options, setOptions] = useState([]);



  const toggle = () => setModal(!modal); 

  const generatePDF = () => {

    const report = new JsPDF('portrait','pt','a1');
    report.html(document.querySelector('#report')).then(() => {
        report.save('reportAgendaSEB.pdf');
    })};


    useEffect(() => {
      // Fetch data from API
      fetch('https://www.agendaseb.com.br/agendaseb/api/unidadesnegocios')
        .then(response => response.json())
        .then(data => setOptions(data))
        .catch(error => console.log(error));
    }, []);


    const handleSelectChange = (event) => {
      setSelectedValue(event.target.value);
    };
  

  useEffect(() => {
    console.log(selectedValue);
      const trocasData = async () =>{
      const api = await fetch(`https://www.agendaseb.com.br/agendaseb/api/eventos_select/${selectedValue}`)
      const docs = await api.json();
      setData(docs);
       console.log(docs);     
      
    };
    trocasData();
  }, [selectedValue]);



  const handleDateChange = (event, key) => {
    const newDate = new Date(event.target.value);
    setDateRange((prev) => ({ ...prev, [key]: newDate }));
  };

 

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.createdAt);
    return (!dateRange.start || itemDate >= dateRange.start ) && (!dateRange.end || itemDate <= dateRange.end);
  })


  




  const columns = [{
    id: 'createdAt',
    displayName: 'Data'
  }, 
  {
    id: 'unidadenegocio_nome',
    displayName: 'Unidade'
  },
  {
    id: 'title',
    displayName: 'Título'
  },
  {
    id: 'start',
    displayName: 'Data do Evento'
  },
  {
    id: 'horario',
    displayName: 'Hora do Evento'
  },
  {
    id: 'local',
    displayName: 'Local'
  },
  {
    id: 'desc',
    displayName: 'Descrição'
  },
];



  return (
    
    <div className='main'>


   
  

  



        <div id="report" name="report" className="section section-buttons" style={{margin:40}}>
 
        <select value={selectedValue} onChange={handleSelectChange} style={{padding:12}}>
          <option>Selecione a Unidade</option>
        {options.map((option) => (
          <option key={option._id} value={option._id}>{option.unidadenegocio}</option>
        ))}
      </select>
   
      &nbsp;&nbsp;&nbsp;

      <CsvDownloader
       style={{fontWeight:"600", padding:10}}
        filename="PlanilhaSebStore"
        extension=".csv"
        separator=";"
        columns={columns}
        datas={data}
        text="Exportar Planilha" />
&nbsp;&nbsp;&nbsp;
<button  style={{fontWeight:"600", padding:10}} onClick={generatePDF} type="button">Exportar PDF</button>




<br /><br />
      <Table striped>
      <thead>
        <tr>
         
          <th>Data</th>
          <th>Unidade</th>  
          <th>Título</th>  
          <th>Data</th> 
          <th>Horário</th> 
          <th>Local</th>
          <th>Descrição</th>   
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <p className='no-data-message'>Nenhum evento encontrado.</p>
        ) : (
          data.map((item3) => (
            <tr key={item3._id}>    
            <td> {moment(item3.createdAt).format('DD/MM/YYYY')}</td> 


            <td style={{fontWeight:400}}> 
            <div style={{backgroundColor:`${item3.cor}`, color:"#FFF", padding:5}}>{item3.unidadenegocio_nome}</div></td>  
            <td style={{fontWeight:400}}> 
       
           {item3.title}
   
            
            </td> 
            <td> {moment(item3.start).format('DD/MM/YYYY')}</td>    
            <td> {item3.horario}</td>   
           
            <td style={{fontWeight:400}}> {item3.local}</td>  
            <td style={{fontWeight:400}}> {item3.desc}</td>  
           


           

            </tr>
          ))
        )}
        </tbody>
      </Table>
   
      </div>
    </div>
  );
  }
  export default App;