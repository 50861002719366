import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';


import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class EditUnidadeNegocio extends Component {


    constructor(props) {
        super(props);
        this.handleChange_unidadenegocio = this.handleChange_unidadenegocio.bind(this);
        this.handleChange_cor = this.handleChange_cor.bind(this);
    
        this.state = {
            listunidadenegocio : [],
            unidadenegocio : props.unidadenegocio,
            cor : props.cor,
            displayColorPicker: false,
            open: true,
            redirect: false
            };
      }


       componentDidMount(){        
        this.loadunidadenegocio();
      };

      loadunidadenegocio = async () => {
        const { id } = this.props.match.params;     
        const response = await api.get(`/api/unidadesnegocios/${id}`);
        this.setState({
          listunidadenegocio: response.data,   
        });
      };


      handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleClose = () => {
        this.setState({ displayColorPicker: false })
      };

      handleChange_unidadenegocio = (event) => {
        this.setState({unidadenegocio: event.target.value});
      };
      

      handleChange_cor = (event) => {
        this.setState({ cor: event.hex });
      };

    
  
      handleUpdate = event => {
        event.preventDefault();
        const { id } = this.props.match.params; 
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://www.agendaseb.com.br/agendaseb/api/unidadesnegocios/${id}`, {     
        unidadenegocio: this.state.unidadenegocio,
      cor: this.state.cor,

         }, config)
          .then(res => {
            alert('Unidade alterada com sucesso!');
            this.props.history.push("/admin/unidadesnegocios");
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }
  
      deleteDados = async () => {   
        const { id } = this.props.match.params; 
        const url = 'https://www.agendaseb.com.br/agendaseb/api/unidadesnegocios';
        await fetch(url + "/" + id, {
          method: 'DELETE'
        }).then(() => {
          alert('Unidade excluída com sucesso!');
          this.props.history.push("/admin/unidadesnegocios");
        }).catch(err => {
          console.error(err)
        });
        };
  

    render() {
        const { listunidadenegocio,  open } = this.state; 
        const popover = {
          position: 'absolute',
          zIndex: '2',
        }
        const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        } 
        return (
            
            
          <Dialog fullScreen open={open} TransitionComponent={Transition}>

              
   
 
          <AppBar className="appBar" style={{backgroundColor:listunidadenegocio.cor,}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; {listunidadenegocio.unidadenegocio}
                  </Typography>
                 
              </td>
                  <td align="right">
                    <Link to={`/admin/unidadesnegocios`}>
                  <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  </Link>
              </td>
                </tr>
              </table>

            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  

            {listunidadenegocio ? (
          <form className="register-form" onSubmit={this.handleUpdate}>


          <GridContainer>  
        
              <GridItem xs={12} sm={12} md={4}>  
              <label htmlFor="unidadenegocio">Unidade de negócio</label>        
              <input className="input_text"
                name="unidadenegocio"
                id="unidadenegocio"
                type="text"
                placeholder={listunidadenegocio.unidadenegocio}
                value={this.state.unidadenegocio}
                required="required"
                onChange={this.handleChange_unidadenegocio}
                disabled="disabled"
                />                     
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>  
              <label htmlFor="cor">Cor de referência</label>                    
                <input className="input_text"
                name="cor"
                id="cor"
                type="text"
                required="required"
                placeholder={listunidadenegocio.cor}
                value={this.state.cor}
                onClick={ this.handleClick }
                onChange={this.handleChange_cor}
                disabled="disabled"
                />


{ this.state.displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleClose }/>
          <SwatchesPicker              
     color={ this.state.cor }
     onChange={ this.handleChange_cor }
   />
        </div> : null } 


              </GridItem>  
                    
        
                 
            </GridContainer>





          </form>
        ) : (
          <div>
           Senão
          </div>
        )}
           
              

           <div style={{position:"absolute", right:20, marginTop:10}}>
            <table>
              <tr>
              <td style={{verticalAlign:"middle", width:150}}>
                  <div style={{paddingTop:20}}>
                  <a onClick={this.deleteDados} style={{border:0, marginTop:5, backgroundColor:"transparent", cursor:"pointer",textDecoration:"none", color:"#f44336"}}>Excluir Unidade</a>              
                  </div>
             </td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                  <button onClick={this.handleUpdate} type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:`${listunidadenegocio.cor}`}}> Salvar </button>                    
                  </div>
                </td>
            </tr>
              
            </table>
</div>


        
          
            </article>
            </div>
         

           


        </Dialog>
          
       
        );
    }
}