import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';


import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { FormGroup, Label, Input} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';

  import 'react-toastify/dist/ReactToastify.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AddEventos extends Component {


    state = {
      unidadenegocio_id: [],
      user:"",
      start:"",
      end:"",
      horario:"",
      unidadenegocio_id2:"",
      unidadenegocio_nome:"",
      cor:"",
      title:"",
      publico:"",
      local:"",
      desc:"",
      open: true,
      redirect: false
      };

      async componentDidMount(){     
        const { id } = this.props.match.params;
        const response = await api.get(`/api/unidadesnegocios/${id}`);
        this.setState ({
          unidadenegocio_id: response.data,
        });         
      };

     
      handleChange_user = (event) => {
        this.setState({user: event.target.value});
      };

      handleChange_start = (event) => {
        this.setState({start: event.target.value});
      };

      handleChange_end = (event) => {
        this.setState({end: event.target.value});
      };

      handleChange_horario = (event) => {
        this.setState({horario: event.target.value});
      };

      handleChange_unidadenegocio_id2 = (event) => {
        this.setState({unidadenegocio_id2: event.target.value});
      };

      handleChange_unidadenegocio_nome = (event) => {
        this.setState({unidadenegocio_nome: event.target.value});
      };

      handleChange_cor = (event) => {
        this.setState({cor: event.target.value});
      };

      handleChange_title = (event) => {
        this.setState({title: event.target.value});
      };

      handleChange_publico = (event) => {
        this.setState({publico: event.target.value});
      };

      handleChange_local = (event) => {
        this.setState({local: event.target.value});
      };

      handleChange_desc = (event) => {
        this.setState({desc: event.target.value});
      };

      
      
      handleSubmit = event => {
        event.preventDefault();
        const { unidadenegocio_id } = this.state;
        const existingLogin = localStorage.getItem('user');
        const user = JSON.parse(existingLogin);       
        const user_id = user._id;
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.post('https://www.agendaseb.com.br/agendaseb/api/eventos', {  
        
      user:user_id,
      start: Moment(this.state.start).add(642, 'seconds'),
      end: Moment(this.state.end).add(642, 'seconds'),
      unidadenegocio_id: unidadenegocio_id._id,
      horario: this.state.horario,
      unidadenegocio_nome: unidadenegocio_id.unidadenegocio,
      cor: unidadenegocio_id.cor,
      title: this.state.title,
      publico: this.state.publico,
      local:this.state.local,
      desc: this.state.desc,
         }, config)
          .then(res => {
            alert('Evento criado com sucesso!');
            this.props.history.push(`/unidadenegocio/${unidadenegocio_id._id}`);
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }


    render() {

      const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }

        const { open, unidadenegocio_id } = this.state;  
        
        return (
      
          <Dialog fullScreen open={open} TransitionComponent={Transition}>
          <AppBar className="appBar" style={{backgroundColor:`${unidadenegocio_id.cor}`,}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; Criar evento / {unidadenegocio_id.unidadenegocio}&nbsp;&nbsp;&nbsp;
                  </Typography>
                 
              </td>
                  <td align="right">
                  <Link to={`/unidadenegocio/${unidadenegocio_id._id}`}>
              <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                <CloseIcon />
              </IconButton>
              </Link>
              </td>
                </tr>
              </table>



             
                 
            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  
           
              <form className="register-form" onSubmit={this.handleSubmit}>


              <GridContainer>  
            
                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="nome">Título do evento</label>    
                  <input className="input_text"
                  placeholder="Digite um título..."
                    name="title"
                    id="title"
                    type="text"
                    required="required"
                    onChange={this.handleChange_title}
                    />                     
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>  
       

<FormGroup>
    <Label for="start">
      Data do início   </Label>
    <Input
      id="start"
      style={{padding:11}}
      placeholder="date placeholder"
      type="date"
      placeholder="Selecione uma data de início..."
      name="start"
      required="required"
      onChange={this.handleChange_start}
    />
  </FormGroup>

                          </GridItem> 

                           <GridItem xs={12} sm={12} md={4}>  
       

<FormGroup>
    <Label for="end">
      Data do término
    </Label>
    <Input
      id="end"
      style={{padding:11}}
      placeholder="date placeholder"
      type="date"
      placeholder="Selecione uma data de término..."
      name="end"
      required="required"
      onChange={this.handleChange_end}
    />
  </FormGroup>

                          </GridItem>  

                          <GridItem xs={12} sm={12} md={4}>  
       

       <FormGroup>
           <Label for="end">
             Horário
           </Label>
<Input
 style={{padding:11}}
      id="horario"
      name="horario"
      type="time"
      placeholder="Selecione uma data de término..."
      name="horario"
      required="required"
      onChange={this.handleChange_horario}
    />




         </FormGroup>
       
                                 </GridItem>  


                                 <GridItem xs={12} sm={12} md={4}>  

                                 
                  <label htmlFor="publico">Público</label>    
                  <input className="input_text"
                  placeholder="Defina o público..."
                    name="publico"
                    id="publico"
                    type="text"
                    required="required"
                    onChange={this.handleChange_publico}
                    />                     
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="local">Local</label>    
                  <input className="input_text"
                  placeholder="Qual o local?"
                    name="local"
                    id="local"
                    type="text"
                    required="required"
                    onChange={this.handleChange_local}
                    />                     
                  </GridItem>

                                
                </GridContainer>
                <div> 
                          <label htmlFor="cor">Descrição do evento</label>                    
                    <textarea style={{height:200}} className="input_text"
                    placeholder="Descreva aqui o evento..."
                    name="desc"
                    id="desc"
                    type="text"
                    onChange={this.handleChange_desc}
                    />
                          </div>  

            <div style={{position:"absolute", right:20, marginTop:20}}>
            <table>
              <tr>        
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                    <button type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:`${unidadenegocio_id.cor}`}}> Salvar </button>              
                  </div>
                </td>
            </tr>
              
            </table>
</div>
            
            
  


              </form>
            

        
          
            </article>
            </div>
         
        </Dialog>
          
       
        );
    }
}