import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Logout extends Component {
    state = {
        navigate: false,
    };

    componentDidMount(){
        this.logout();
      };
    

    logout = () => {
        localStorage.clear("@NimbleSEB-Token");
        this.setState({ navigate: true,});
    };

    render() {
        const { navigate } = this.state;

        if(navigate) {
            return <Redirect to="/" push={true} />;
         }
         return null;
    }
}

export default Logout;