import React, { Component } from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Link } from 'react-router-dom';
import api from "../../services/api";
import './styles.css';


import Moment from 'moment';
import 'moment/locale/pt';


export default class Usuarios extends Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      unidades: [],
      unidadesInfo: {},
      page: 1,
    }
}   

  componentDidMount(){
    this.loadUnidades();
  };


  loadUnidades = async () => {
    const response = await api.get(`/api/unidadesnegocios`);  
    this.setState({
      unidades: response.data,
               
    });
  };



  


 
  render() {
 
    const { unidades, page , unidadesInfo} = this.state;   
    
    const existingLogin = localStorage.getItem('user');
    const user = JSON.parse(existingLogin);       
    const user_tipo = user.tipo;
    const user_unidadenegocio_id = user.unidadenegocio_id;
    return (
    
    <GridContainer>     


<GridItem xs={12} sm={12} md={12}>
        <Card>
          
             <CardBody>
<GridContainer>    




                 {user_tipo === "1" ? (
                     
                     <GridItem xs={12} sm={12} md={12}>
                     <div align="right" style={{marginBottom:20, marginTop:20,}}>
                     <Link style={{
                     backgroundColor:'#333',   
                     height: 42,
                     borderRadius: 5,
                     color: '#fff',
                     fontSize: 14,
                     textDecoration: 'none',
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding: 10,
                     paddingLeft: 30,
                     paddingRight: 35,
                     fontWeight:400
                     }} to={"/uservip"}>Usuários VIP</Link>
                   </div>
                 </GridItem>
                   
         
                   )
                   : (
                    <div></div>
                   )}   

                         
         
             {unidades.map(unidadesnegocios => (
        <GridItem xs={12} sm={12} md={3} key={unidadesnegocios._id}>

{ user_tipo === "1" ? (


        <div className="doadores-list">
            <article >
        
              <strong style={{color:unidadesnegocios.cor}}>
              {unidadesnegocios.unidadenegocio}
              </strong>
             
              <p>
                <div style={{width:"100%", height:10, borderRadius:6, backgroundColor: unidadesnegocios.cor, color:unidadesnegocios.cor}}>
                &nbsp;
                </div>
              </p>
              <Link style={{borderColor:"#DDDDDD", backgroundColor:"#DDDDDD", color:"#333", fontSize:14, fontWeight:400}} to={`/usuarios_negocios/${unidadesnegocios._id}`}>Usuários</Link>
          
            </article>
            </div>   

             )
            : (
             <div></div>
            )}          
                
           </GridItem>
           
             ))} 


                   


</GridContainer>    

      </CardBody>
      </Card>
      </GridItem>
    </GridContainer>  

    )
  }
}

