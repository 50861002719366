import React, { Component } from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Link } from 'react-router-dom';
import api from "../../services/api";
import './styles.css';

import img_empresa from "../../assets/img/logo_empresa.png";

import Moment from 'moment';
import 'moment/locale/pt';


export default class UnidadesNegocios extends Component {
  

state = {
      unidades: [],
      unidades2: [],
      unidades3: [],
      unidades4: [],
      unidades5: [],
      unidades6: [],
      unidades7: [],
      unidades8: [],
      unidades9: [],
      unidades10: [],
      unidadesInfo: {},
      page: 1,
}

  componentDidMount(){
    this.loadUnidades();
    this.loadUnidades2();
    this.loadUnidades3();
    this.loadUnidades4();
    this.loadUnidades5();
    this.loadUnidades6();
    this.loadUnidades7();
    this.loadUnidades8();
    this.loadUnidades9();
    this.loadUnidades10();
  };


  loadUnidades = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades: response.data,
    });
  };


  loadUnidades2 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades2: response.data,
    });
  };

  loadUnidades3 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades3: response.data,
    });
  };


  loadUnidades4 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades4: response.data,
    });
  };

  loadUnidades5 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades5: response.data,
    });
  };


  loadUnidades6 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades6: response.data,
    });
  };

  loadUnidades7 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades7: response.data,
    });
  };


  loadUnidades8 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades8: response.data,
    });
  };


  loadUnidades9 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades9: response.data,
    });
  };


  loadUnidades10 = async () => {
    const response = await api.get(`/api/unidadesnegocios_list`);  
    this.setState({
      unidades10: response.data,
    });
  };
 
  render() {
 
    const { unidades, unidades2, unidades3, unidades4, unidades5, unidades6, unidades7, unidades8, unidades9, unidades10} = this.state;    
    const existingLogin = localStorage.getItem('user');
        const user = JSON.parse(existingLogin);       
        const user_tipo = user.tipo;
        const user_negocio = user.unidadenegocio_id;
        const user_negocio2 = user.unidadenegocio_id2;
        const user_negocio3 = user.unidadenegocio_id3;
        const user_negocio4 = user.unidadenegocio_id4;
        const user_negocio5 = user.unidadenegocio_id5;
        const user_negocio6 = user.unidadenegocio_id6;
        const user_negocio7 = user.unidadenegocio_id7;
        const user_negocio8 = user.unidadenegocio_id8;
        const user_negocio9 = user.unidadenegocio_id9;
        const user_negocio10 = user.unidadenegocio_id10;
     
    return (
    
    <GridContainer>     


<GridItem xs={12} sm={12} md={12}>
        <Card>
          
             <CardBody>
<GridContainer>    

{user_tipo === "1" ? (
<GridItem xs={12} sm={12} md={12}>
<span style={{fontWeight:600}}> Unidades de negócio</span> ({unidades.length}) 

<hr style={{borderWidth:1, borderColor:"#ccc"}} />
</GridItem>

)
: (
 <div></div>
)}   
          

{user_tipo === "1" ? (
                     
                     <GridItem xs={12} sm={12} md={12}>
                     <div align="right" style={{marginBottom:20, marginTop:0,}}>
                     <Link style={{
                     backgroundColor:'#333',   
                     height: 42,
                     borderRadius: 5,
                     color: '#fff',
                     fontSize: 14,
                     textDecoration: 'none',
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding: 10,
                     paddingLeft: 30,
                     paddingRight: 35,
                     fontWeight:400
                     }} to={"/addunidadesnegocios"}>+ Criar unidade</Link>
                   </div>
                 </GridItem>
                   
         
                   )
                   : (
                    <div></div>
                   )}   


        
         
             {unidades.map(unidadesnegocios => (
        <GridItem xs={12} sm={12} md={12} key={unidadesnegocios._id}>
            {user_negocio === unidadesnegocios._id || user_negocio2 === unidadesnegocios._id || user_negocio3 === unidadesnegocios._id || user_negocio4 === unidadesnegocios._id || user_negocio5 === unidadesnegocios._id || user_negocio6 === unidadesnegocios._id || user_negocio7 === unidadesnegocios._id  || user_negocio8 === unidadesnegocios._id || user_negocio9 === unidadesnegocios._id || user_negocio10 === unidadesnegocios._id ||  user_tipo === "1" ? (
        <div className="doadores-list">
            <article >
             
              <strong style={{color:unidadesnegocios.cor}}>
              {unidadesnegocios.unidadenegocio}
              </strong>
             
              <p>
                <div style={{width:"100%", height:10, borderRadius:6, backgroundColor: unidadesnegocios.cor, color:unidadesnegocios.cor}}>
                &nbsp;
                </div>
              </p>

            

              <Link style={{borderColor:"#DDDDDD", backgroundColor:"#DDDDDD", color:"#333", fontSize:14, fontWeight:400}} to={`/unidadenegocio/${unidadesnegocios._id}`}>Eventos</Link>
         
            

          
            </article>
            </div>    
            
            )
            : (
             <div></div>
            )}   
                
           </GridItem>
           
             ))} 



</GridContainer>    


               </CardBody>    
      </Card>
      </GridItem>
    </GridContainer>

    )
  }
}

