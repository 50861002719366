import React, { Component } from "react";
import api from "../../services/api";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";



import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class Users_New extends Component {


    state = {
      usuarios: [],
      usuariosInfo: {},
      page: 1,
      open:true
      };
    
      async componentDidMount(){   
        this.loadUsers();  
      };

      loadUsers = async (page = 1) => {
        const response = await api.get(`/api/users?page=${page}`);  
        const { docs, ...usuariosInfo } = response.data;
        this.setState({
          usuarios: docs,
          usuariosInfo,
           page  
        });
      };
    
      prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadUsers(pageNumber);
      };
    
      nextPage = () => {
        const { page, usuariosInfo } = this.state;
        if (page === usuariosInfo.pages) return;
        const pageNumber = page + 1;
        this.loadUsers(pageNumber);
      };
    

    render() {
        const { open, usuarios, page , usuariosInfo} = this.state;   
        return (
      
          <Dialog fullScreen open={open} TransitionComponent={Transition}>
          <AppBar className="appBar" style={{backgroundColor:"#666"}}>
            <Toolbar>
              
              <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; Usuários



                  </Typography>
                 
              </td>
                  <td align="right">
                    <Link to={`/admin/usuarios`}>
                  <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  </Link>
              </td>
                </tr>
              </table>
                   
            </Toolbar>
          </AppBar>
      <br />&nbsp;
          <GridContainer style={{padding:30,}}>  

           <GridItem xs={12} sm={12} md={12}>
                      <div align="right" style={{marginBottom:20, marginTop:20,}}>
                      <Link style={{
                      backgroundColor:"#666",   
                      height: 42,
                      borderRadius: 5,
                      color: '#fff',
                      fontSize: 14,
                      textDecoration: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                      paddingLeft: 30,
                      paddingRight: 35,
                      fontWeight:400
                      }} to={`/adduservip/2`}>+ Criar usuário</Link>
                    </div>
                  </GridItem>


          {usuarios.map(users => (
        <GridItem xs={12} sm={12} md={3} key={users._id}>
        <div className="doadores-list">
            <article >
            
              <p style={{fontSize:14, color:"#333"}}>
                Criado em <strong>{Moment(users.createdAt).format('L')}</strong>
              </p>

             
         
              <strong style={{color:"#666", fontWeight:600}}>
              {users.nome}
              </strong>
       
  
              <p style={{fontSize:14,}}>
              {users.email}
              </p>
  
             
         
              <Link style={{borderColor:"#666", backgroundColor:"#666", color:"#FFF", fontSize:14, fontWeight:400}} to={`/edituser/${users._id}`}>Alterar</Link>
         
          
            </article>
            </div>          
                
           </GridItem>
           
             ))} 


         
         </GridContainer>   
         <div style={{padding:20}}>
         {usuarios.length != 0 ? (
                     
                     <div className="doadores-list">
                     <div className="actions">
                         <button style={{fontWeight:400, width:120,fontSize:14, backgroundColor:"#ddd", color:"#333"}} disabled={page === 1} onClick={this.prevPage}>Anterior</button>
                         <button disabled={page === usuariosInfo.pages} style={{fontWeight:400, width:120,fontSize:14,  backgroundColor:"#ddd", color:"#333"}} onClick={this.nextPage}>Próxima</button>
                     </div>
                     </div>
                   
         
                   )
                   : (
                    <div></div>
                   )} 
        
        </div>
        </Dialog>
          
       
        );
    }
}