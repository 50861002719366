import Calendar from "@material-ui/icons/Event"
import Person from "@material-ui/icons/SettingsOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";
import logoff from "@material-ui/icons/Close";
import UnidadesNegociosIco from "@material-ui/icons/Apps";
import RelatoriosIco from "@material-ui/icons/Description";

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Negocios from "./views/UnidadesNegocios/UnidadesNegocios.js";
import Usuarios from "./views/Usuarios/Usuarios.js";
import Config from "./views/Config/Config";
import Sair from "./views/Sair/Sair";
import Relatorios from "./views/Relatorios/Relatorios.js";
import Relatorios2 from "./views/Relatorios/Relatorios2.js";
import Relatorios3 from "./views/Relatorios/Relatorios3.js";

const dashboardRoutes = [
  {
    path: "/calendario",
    name: "Calendário",
    icon: Calendar,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/unidadesnegocios",
    name: "Eventos",
    icon: UnidadesNegociosIco,
    component: Negocios,
    layout: "/admin"
  },

  {
    path: "/relatorios",
    name: "Relatório por Data",
    icon: RelatoriosIco,
    component: Relatorios2,
    layout: "/admin"
  },

  {
    path: "/relatorios2",
    name: "Relatório por Unidades",
    icon: RelatoriosIco,
    component: Relatorios3,
    layout: "/admin"
  },

  {
    path: "/usuarios",
    name: "Usuários",
    icon: AccountCircle,
    component: Usuarios,
    layout: "/admin"
  },

   {
    path: "/config",
    name: "Configurações",
     icon: Person,
    component: Config,
    layout: "/admin"
  },

  {
    path: "/sair",
    name: "Sair",
    icon: logoff,
    component: Sair,
    layout: "/admin"
  },


];

export default dashboardRoutes;
