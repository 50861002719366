import React, { Component } from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import api from "../../services/api";
import './styles.css';

import { Link } from 'react-router-dom';

import Moment from 'moment';
import 'moment/locale/pt';


export default class Config extends Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
        config: [],  
    }
}   

  componentDidMount(){
    this.loadConfig();    
  };

  loadConfig = async () => {
    const existingLogin = localStorage.getItem('user');
    const user = JSON.parse(existingLogin);   

    const user_id = user._id;

    const response = await api.get(`/api/users/${user_id}`);  
    this.setState({
        config: response.data     
    });
  };



  render() {
 
    const { config } = this.state;    

    return (
    
    <GridContainer>

                
      <GridItem xs={12} sm={12} md={12}>
        <Card>


          <CardBody>
    
            <GridContainer>  
                <GridItem xs={12} sm={12} md={4}>

                    <div className="group_info">
                    <p>
                    <strong>Data do cadastro</strong>
                    </p>

                    <p>
                    {Moment(config.createdAt).format('L')}   
                    </p>

                    </div>

                </GridItem>

                <GridItem xs={12} sm={12} md={4}>

                    <div className="group_info">
                    <p>
                    <strong>Nome completo</strong>
                    </p>

                    <p>
                    {config.nome}           
                    </p>

                    </div>

                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>

                    <div className="group_info">
                    <p>
                    <strong>E-mail</strong>
                    </p>

                    <p>
                    {config.email}           
                    </p>

                    </div>

                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                    <div className="doadores-list" style={{borderColor:"#FFF", backgroundColor:"#FFFFFF"}}>
            <article >
                    <Link style={{borderColor:config.cor, backgroundColor:config.cor, color:"#fff", fontSize:14, fontWeight:400}} to={`/editsenha/${config._id}`}>Alterar senha</Link>

        </article>
        </div>
        </GridItem>
            </GridContainer>
        
          </CardBody>

        </Card>      
      </GridItem>   
    </GridContainer>

    )
  }
}

