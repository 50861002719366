import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';


import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import 'bootstrap/dist/css/bootstrap.min.css';

import { FormGroup, Label, Input} from 'reactstrap';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class EditEvento extends Component {


    constructor(props) {
        super(props);
   
        this.state = {
            listevento : [],
         
            displayColorPicker: false,
            open: true,
            redirect: false
            };

            
      }


       componentDidMount(){        
        this.loadEvento();
      };

      loadEvento = async () => {
        const { id } = this.props.match.params;     
        const response = await api.get(`/api/eventos/${id}`);
        this.setState({
          listevento: response.data,   
        });
      };


      handleChange_title = (event) => {
        event.persist();
        this.setState({title: event.target.value});
      };      

      handleChange_start = (event) => {
        event.persist();
        this.setState({ start: event.target.value});
      };

      handleChange_end = (event) => {
        event.persist();
        this.setState({ end: event.target.value});
      };

      handleChange_horario = (event) => {
        event.persist();
        this.setState({ horario: event.target.value});
      };

      handleChange_publico = (event) => {
        event.persist();
        this.setState({ publico: event.target.value});
      };

      handleChange_local = (event) => {
        event.persist();
        this.setState({ local: event.target.value});
      };

      handleChange_desc = (event) => {
        event.persist();
        this.setState({ desc: event.target.value});
      };

    
  
      handleUpdate = event => {
        event.preventDefault();
        const { id } = this.props.match.params; 
        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.put(`https://www.agendaseb.com.br/agendaseb/api/eventos/${id}`, {  
        start: Moment(this.state.start),
        end: Moment(this.state.end),   
        title: this.state.title,
        horario:this.state.horario,
        publico:this.state.publico,
        local:this.state.local,
        desc: this.state.desc,

         }, config)
          .then(res => {
            alert('Evento alterado com sucesso!');
            this.props.history.push("/admin/unidadesnegocios");
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }
  
      deleteDados = async () => {   
        const { id } = this.props.match.params; 
        const url = 'https://www.agendaseb.com.br/agendaseb/api/eventos';
        await fetch(url + "/" + id, {
          method: 'DELETE'
        }).then(() => {
          alert('Evento excluído com sucesso!');
          this.props.history.push("/admin/unidadesnegocios");
        }).catch(err => {
          console.error(err)
        });
        };
  

    render() {
        const { listevento,  open } = this.state; 
        const popover = {
          position: 'absolute',
          zIndex: '2',
        }
        const cover = {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        } 
        return (
            
            
          <Dialog fullScreen open={open} TransitionComponent={Transition}>

              
   
 
          <AppBar className="appBar" style={{backgroundColor:listevento.cor,}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; {listevento.titulo}
                  </Typography>
                 
              </td>
                  <td align="right">
                    <Link to={`/admin/unidadesnegocios`}>
                  <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  </Link>
              </td>
                </tr>
              </table>

            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  

            {listevento ? (
          <form className="register-form" onSubmit={this.handleUpdate}>




<GridContainer>  
            
            <GridItem xs={12} sm={12} md={4}>  
            <label htmlFor="nome">Título do evento</label>    
            <input className="input_text"
              name="title"
              id="title"
              type="text"
              onChange={this.handleChange_title}
              defaultValue={listevento.title}
              />          
              
                        
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>  

<FormGroup>
    <Label for="start">
      Data do início
    </Label>
    <Input
      id="start"
      style={{padding:11}}
      type="date"
      name="start"
      required="required"
      onChange={this.handleChange_start}
      defaultValue={Moment(listevento.start).format('L')}
    />
  </FormGroup>


                    </GridItem> 

                     <GridItem xs={12} sm={12} md={4}>  
 


<FormGroup>
    <Label for="end">
      Data do término
    </Label>
    <Input
      id="end"
      style={{padding:11}}
      type="date"
      name="end"
      required="required"
      onChange={this.handleChange_end}
      defaultValue={Moment(listevento.end).format('L')}
    />
  </FormGroup>




                    </GridItem>  

                    <GridItem xs={12} sm={12} md={4}>  
       


       
<FormGroup>
           <Label for="end">
             Horário
           </Label>
<Input
 style={{padding:11}}
      id="horario"
      name="horario"
      type="time"
      name="horario"
      required="required"
      onChange={this.handleChange_horario}
      defaultValue={listevento.horario}
    />




         </FormGroup>
       

     
       
                                 </GridItem>  


                                 <GridItem xs={12} sm={12} md={4}>  

                                 
                  <label htmlFor="publico">Público</label>    
                  <input className="input_text"
                    name="publico"
                    id="publico"
                    type="text"
                    required="required"
                    onChange={this.handleChange_publico}
                    defaultValue={listevento.publico}
                    />                     
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="local">Local</label>    
                  <input className="input_text"
                  
                    name="local"
                    id="local"
                    type="text"
                    defaultValue={listevento.local}
                    required="required"
                    onChange={this.handleChange_local}
                    />                     
                  </GridItem>
                          
          </GridContainer>
          <div> 
                    <label htmlFor="cor">Descrição do evento</label>                    
              <textarea style={{height:200}} className="input_text"
              name="desc"
              id="desc"
              type="text"
              defaultValue={listevento.desc} 
              onChange={this.handleChange_desc}
               />
                    </div>  

                    <div style={{position:"absolute", right:20, marginTop:10}}>
            <table>
              <tr>
              <td style={{verticalAlign:"middle", width:150}}>
                  <div style={{paddingTop:20}}>
                  <a onClick={this.deleteDados} style={{border:0, marginTop:5, backgroundColor:"transparent", cursor:"pointer",textDecoration:"none", color:"#f44336"}}>Excluir evento</a>              
                  </div>
             </td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                  <button onClick={this.handleUpdate} type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:`${listevento.cor}`}}> Salvar </button>                    
                  </div>
                </td>
            </tr>
              
            </table>
</div>
      
      



         
                 


          </form>
        ) : (
          <div>
           Senão
          </div>
        )}
           
              

  


        
          
            </article>
            </div>
         

           


        </Dialog>
          
       
        );
    }
}