import React, { Component } from "react";
import api from "../../services/api";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

import LocalOffer from "@material-ui/icons/LocalOffer";

import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import events from './events';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Link } from 'react-router-dom';

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';


import { Modal, Button, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";


const A = 65 // cógido de caractere ASCII

const localizer = momentLocalizer(moment)



export default class UnidadesNegocios extends Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      unidadesnegocios: [],
      eventos:[],
      unidadesnegociosInfo: {},
      page: 1,
      modal: false,
      eventosunidades2:[],
      eventosunidadesInfo2:{},
    }
}   


async componentDidMount() {
  this.loadEventos();
  this.loadEventosModal();
  
}




loadEventos = async () => {
  const response = await api.get('/api/eventos');
  const { docs } = response.data;        
  this.setState({
    eventos: docs,
  });

  
  console.log(this.state.eventos);
};

loadEventosModal = async (letter) => {  
  

  const response = await api.get(`/api/eventos/${letter}`);  
  this.setState({
    eventosunidades2: response.data,
    modal: !this.state.modal
  });

  const { eventosunidades2 } = this.state;
  const id2 = eventosunidades2.unidadenegocio_id;  
  const response2 = await api.get(`/api/unidadesnegocios/${id2}`);
  this.setState ({
    unidadesnegocios: response2.data,
  }); 
};

toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
}




render() {
  


  
  // Create styles
const styles = StyleSheet.create({
  page: {
    width: 600,
    height: 600,
  },
  section: {
    margin: 10,
    padding: 10,
  }
});


 
  const { unidadesnegocios, eventos, eventosunidades2 } = this.state;  
  
  function Event({ event }) {
    return (
        <span>
            <strong style={{textTransform:"uppercase", fontWeight:"600"}}>{event.unidadenegocio_nome}</strong>&nbsp; | 
            &nbsp;{event.title}
        </span>
    )
  }
  
  return (
    <div> 

      <div>

        <div style={{fontSize:14, marginTop:20}}>
          <button style={{backgroundColor:"#388E3C", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Escolas de Alta Performance


&nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#B71C1C", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Maple Bear


          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#FF6F00", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Escolas Premium

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#1976D2", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Escolas Concept

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#000", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Grupo SEB


          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#969696", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Corporativo SEB

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#FFEB3B", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;CEFIC

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#03A9F4", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Instituto SEB

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#E91E63", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Ensino Superior

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#1A237E", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Conexia Educação





          

          </div>

          
        

     



            <Modal funk={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}><span style={{fontWeight:600}}>{eventosunidades2.title}</span></ModalHeader>
          <ModalBody>
          <div style={{color: unidadesnegocios.cor, fontWeight:600, marginBottom:10}}>
            {unidadesnegocios.unidadenegocio}
            </div>
            <div style={{width:"100%", height:10, borderRadius:6, backgroundColor: unidadesnegocios.cor,  marginBottom:20}}>
                &nbsp;
            </div>

            <div style={{marginBottom:10}}>
            <span style={{fontWeight:600,}}>Data início</span> - {moment(eventosunidades2.start).format('L')}
            </div>

            <div style={{marginBottom:10}}>
            <span style={{fontWeight:600,}}>Data término</span> - {moment(eventosunidades2.end).format('L')}
            </div>

            <div style={{marginBottom:10}}>
            <span style={{fontWeight:600,}}>Horário</span> - {eventosunidades2.horario}
            </div>

            <div style={{marginBottom:10}}>
            <span style={{fontWeight:600,}}>Público</span> - {eventosunidades2.publico}
            </div>

            <div style={{marginBottom:10}}>
            <span style={{fontWeight:600,}}>Local</span> - {eventosunidades2.local}
            </div>

            <div style={{marginBottom:10}}>
            {eventosunidades2.desc}
            </div>
  
          </ModalBody>

        </Modal>
      </div>


      <Card>
          
          <CardBody>

<div >
    <Calendar
    step={60}
    
    toolbar={true}
          views={{
             
                month: true,
       
          }}
    localizer={localizer}
    components={{
      event: Event,
    }}
      events={eventos}
      
      defaultDate={moment().toDate()}
      onSelectEvent={event => this.loadEventosModal(event._id)}
      eventPropGetter={(event) => ({
        event,
        style: { backgroundColor: event.cor, fontSize:10 }
      })}
      startAccessor='start'
          endAccessor='end'
          showAllEvents={true}
          style={{height: 1650}}

         
          
    /> 
  </div>


  

  </CardBody>
  </Card>
  <div style={{fontSize:14, marginTop:20}}>
          <button style={{backgroundColor:"#388E3C", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Escolas de Alta Performance


&nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#B71C1C", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Maple Bear

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#FF6F00", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Escolas Premium

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#1976D2", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Escolas Concept

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#000", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Grupo SEB


          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#969696", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Corporativo SEB

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#FFEB3B", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;CEFIC

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#03A9F4", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Instituto SEB

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#E91E63", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Ensino Superior

          &nbsp;&nbsp;&nbsp;

          <button style={{backgroundColor:"#1A237E", width:10, height:10, borderRadius:50, paddingTop:10, justifyContent:"flex-start", borderWidth:0,}}></button>
          &nbsp;Conexia Educação





          

          </div>
     
    </div>
  );
}}
