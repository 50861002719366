import React, { Component } from "react";
import api from "../../services/api";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";



import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class UnidadeNegocio extends Component {


    state = {
      unidadenegocio_id : [],
      unidadesnegocios : [],
      eventosunidades: [],
      eventosunidadesInfo: [],
      open: true,
      };
    
      async componentDidMount(){   
        this.loadEventosUnidades();    
        const { id } = this.props.match.params;     
        const response = await api.get(`/api/unidadesnegocios/${id}`);
        this.setState ({
          unidadenegocio_id: response.data,
        }); 
        const { unidadenegocio_id } = this.state;
        const id2 = unidadenegocio_id._id;  
        const response2 = await api.get(`/api/eventos_select/${id2}`);
        this.setState ({
          unidadesnegocios: response2.data,
        }); 
      };

      loadEventosUnidades = async (page = 1) => {
        const { id } = this.props.match.params;  
        const response = await api.get(`/api/eventos_select/${id}`);  
        const { ...eventosunidadesInfo } = response.data;
        this.setState({
          eventosunidades: response.data,
          eventosunidadesInfo,
           page  
        });
      
      };

      prevPage = () => {
        const { page } = this.state;
        if (page === 1) return;
        const pageNumber = page - 1;
        this.loadEventosUnidades(pageNumber);
      };
    
      nextPage = () => {
        const { page, eventosunidadesInfo } = this.state;
        if (page === eventosunidadesInfo.pages) return;
        const pageNumber = page + 1;
        this.loadEventosUnidades(pageNumber);
      };
     

    render() {
        const { unidadenegocio_id, open, unidadesnegocios, eventosunidades } = this.state;

        const existingLogin = localStorage.getItem('user');
    const user = JSON.parse(existingLogin);       
    const user_tipo = user.tipo;
    const user_unidadenegocio_id = user.unidadenegocio_id;


        return (
      
          <Dialog fullScreen open={open} TransitionComponent={Transition}>
          <AppBar className="appBar" style={{backgroundColor:unidadenegocio_id.cor}} backgroundColor={unidadenegocio_id.cor}>
            <Toolbar>
              
              <table style={{width:"100%"}}>
                <tr>
                  <td align="left"><Typography variant="h6" className="title" style={{textTransform:"none"}}>
                    &nbsp; Eventos / {unidadenegocio_id.unidadenegocio} ({unidadesnegocios.length})&nbsp;&nbsp;&nbsp;

            { user_tipo === "1" ? (
                    <Link to={`/editunidadenegocio/${unidadenegocio_id._id}`} style={{color:"#FFF"}}>
                      <EditIcon  style={{paddingTop:0}} />
                    </Link>                )
                : (
                <div></div>
                )}    
              

                  </Typography>
                 
              </td>
                  <td align="right">
                    <Link to={`/admin/unidadesnegocios`}>
                  <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  </Link>
              </td>
                </tr>
              </table>
                   
            </Toolbar>
          </AppBar>
      <br />&nbsp;
          <GridContainer style={{padding:30,}}>  

          <GridItem xs={12} sm={12} md={12}>
                      <div align="right" style={{marginBottom:20, marginTop:20,}}>
                      <Link style={{
                      backgroundColor:`${unidadenegocio_id.cor}`,   
                      height: 42,
                      borderRadius: 5,
                      color: '#fff',
                      fontSize: 14,
                      textDecoration: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                      paddingLeft: 30,
                      paddingRight: 35,
                      fontWeight:400
                      }}  to={`/unidadenegocio2022/${unidadenegocio_id._id}`}>Eventos 2022</Link>

                      &nbsp;&nbsp;

                      <Link style={{
                      backgroundColor:`${unidadenegocio_id.cor}`,   
                      height: 42,
                      borderRadius: 5,
                      color: '#fff',
                      fontSize: 14,
                      textDecoration: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                      paddingLeft: 30,
                      paddingRight: 35,
                      fontWeight:400
                      }} to={`/unidadenegocio2023/${unidadenegocio_id._id}`}>Eventos 2023</Link>

                      &nbsp;&nbsp;

<Link style={{
                      backgroundColor:`${unidadenegocio_id.cor}`,   
                      height: 42,
                      borderRadius: 5,
                      color: '#fff',
                      fontSize: 14,
                      textDecoration: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                      paddingLeft: 30,
                      paddingRight: 35,
                      fontWeight:400
                      }} to={`/unidadenegocio/${unidadenegocio_id._id}`}>Todos</Link>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

<Link style={{
                      backgroundColor:`${unidadenegocio_id.cor}`,   
                      height: 42,
                      borderRadius: 5,
                      color: '#fff',
                      fontSize: 14,
                      textDecoration: 'none',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                      paddingLeft: 30,
                      paddingRight: 35,
                      fontWeight:400
                      }} to={`/addeventos/${unidadenegocio_id._id}`}>+ Criar evento</Link>


                    </div>

                    <div align="right" style={{marginBottom:20, marginTop:20,}}>
                     
                    </div>
                  </GridItem>

           <GridItem xs={12} sm={12} md={12}>
                     
                  </GridItem>


          {
          eventosunidades.map(eventosunidades => (
        
            
        <GridItem xs={12} sm={12} md={3} key={eventosunidades._id}>
        <div className="doadores-list">
       
              <article >
              <p style={{fontSize:14, color:"#333"}}>
              Criado em <strong>{Moment(eventosunidades.createdAt).format('L')}</strong>
            </p> <strong style={{color:unidadenegocio_id.cor, fontWeight:600}}>
              {eventosunidades.title}
              </strong>
                <p style={{fontSize:14, color:"#333"}}>
              <strong style={{fontWeight:600}}>Data início - </strong> {Moment(eventosunidades.start).format('L')} <br />
              <strong style={{fontWeight:600}}>Data término - </strong> {Moment(eventosunidades.end).format('L')} <br />
              <strong style={{fontWeight:600}}> Horário - </strong> {eventosunidades.horario}
              </p> <p style={{fontSize:14, color:"#333"}}>
              <strong style={{fontWeight:600}}>Público - </strong> {eventosunidades.publico} <br />
              <strong style={{fontWeight:600}}>Local - </strong> {eventosunidades.local}
              </p>
  
              <p style={{fontSize:14,}}>
              {eventosunidades.desc}
              </p>
  
             
         
              <Link style={{borderColor:unidadenegocio_id.cor, backgroundColor:unidadenegocio_id.cor, color:"#FFF", fontSize:14, fontWeight:400}} to={`/editevento/${eventosunidades._id}`}>Alterar</Link>
         </article>
          
              

             
         
             

             


             
          
   
            </div>          
                
           </GridItem>
           
             ))} 


         
         </GridContainer>   
         

        

        </Dialog>
          
       
        );
    }
}