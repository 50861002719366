import React, { Component } from "react";
import api from "../../services/api";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt';
import './styles.css';
import { SwatchesPicker  } from 'react-color';



import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AddUnidadesNegocios extends Component {


    state = {
      unidadenegocio : '',
      cor : '',
      displayColorPicker: false,
      open: true,
      redirect: false
      };

      handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleClose = () => {
        this.setState({ displayColorPicker: false })
      };
 
      handleChange_unidadenegocio = (event) => {
        this.setState({unidadenegocio: event.target.value});
      };

      handleChange_cor = (event) => {
        //this.setState({cor: event.target.value});
        this.setState({ cor: event.hex });
      };

    
      
      handleSubmit = event => {
        event.preventDefault();

        const config = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        };
      
      axios.post('https://www.agendaseb.com.br/agendaseb/api/unidadesnegocios', {     
      unidadenegocio: this.state.unidadenegocio,
      cor: this.state.cor,
         }, config)
          .then(res => {
            alert('Unidade criada com sucesso!');
            this.props.history.push("/admin/unidadesnegocios");
          })
          .catch(function (error) {
            alert('Ocorreu algum erro!');
            console.log(error);
        });
      }


    render() {

      const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }

        const { open } = this.state;  
        
        return (
      
          <Dialog fullScreen open={open} TransitionComponent={Transition}>
          <AppBar className="appBar" style={{backgroundColor:'#333',}}>
            <Toolbar>

            <table style={{width:"100%"}}>
                <tr>
                  <td align="left">    <Typography variant="h6" className="title" style={{textTransform:"none"}}>
                &nbsp; Criar unidade de negócio
              </Typography> 
                 
              </td>
                  <td align="right">
                  <Link to={`/admin/unidadesnegocios`}>
              <IconButton edge="start" style={{color:'#fff'}} aria-label="close">
                <CloseIcon />
              </IconButton>
              </Link>
              </td>
                </tr>
              </table>


            
                
            </Toolbar>
          </AppBar>

          <div className="doadores-list2">
            <article>  
           
              <form className="register-form" onSubmit={this.handleSubmit}>


              <GridContainer>  
            
                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="nome">Unidade de negócio</label>    
                  <input className="input_text"
                  placeholder="Digite um título..."
                    name="unidadenegocio"
                    id="unidadenegocio"
                    type="text"
                    required="required"
                    onChange={this.handleChange_unidadenegocio}
                    />                     
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>  
                  <label htmlFor="cor">Cor de referência</label>                    
                    <input className="input_text"
                    placeholder="Selecione uma cor..."
                    name="cor"
                    id="cor"
                    type="text"
                    required="required"
                    value={this.state.cor}
                    onChange={this.handleChange_cor}
                    onClick={ this.handleClick }
                    />
<div style={{width:"100%", height:10, borderRadius:6, backgroundColor: this.state.cor,}}>
                &nbsp;
                </div>
             
        { this.state.displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleClose }/>
          <SwatchesPicker              
     color={ this.state.cor }
     onChange={ this.handleChange_cor }
   />
        </div> : null }
                   
  

 
                  </GridItem>  
                          
                </GridContainer>


            <div style={{position:"absolute", right:20, marginTop:20}}>
            <table>
              <tr>
              <td style={{verticalAlign:"middle", width:150}}>
                  <div>
                  <a href="/admin/unidadesnegocios" style={{border:0, marginTop:17, backgroundColor:"transparent", cursor:"pointer",textDecoration:"none", color:"#f44336"}}>Cancelar</a>              
                  </div>
             </td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="actions">
                    <button type="submit" style={{width:120, fontWeight:400, fontSize:14, backgroundColor:"#333"}}> Salvar </button>              
                  </div>
                </td>
            </tr>
              
            </table>
</div>
            
            
  


              </form>
            

        
          
            </article>
            </div>
         
        </Dialog>
          
       
        );
    }
}