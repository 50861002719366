import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #ddd;

`;

export const Form = styled.form`
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 5px 7px 5px rgba(50, 50, 50, 0.27);

  img {
    width: 200px;
    margin: 0px 0 0px;
  }

  label {
    margin-bottom:20px;
    margin-top:5px;
    font-size:20px;
    font-weight: bold;
    color: #333;
  }

  p {
    color: #EC652B;
    margin-bottom: 15px;
    border: 1px solid #EC652B;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  input {
    flex: 1;
    height: 46px;
    margin-bottom: 15px;
    padding: 15px;
    color: #777;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    &::placeholder {
      color: #999;
      border-radius: 5px;
    }
  }

 
  button {
    color: #fff;
    font-size: 16px;
    background: #333;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
  }

  button:hover {
    background-color:#666 ;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 14;
    color: #999;
    text-decoration: none;
  }
`;